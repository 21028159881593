window.addEventListener("load", () => {
  const forms = document.querySelectorAll("form.add-wine[data-remote]");

  forms.forEach((form) => {
    form.addEventListener("ajax:success", (event) => {
      // Hide the dropdown menu
      const toggle = form
        .closest(".order-options")
        .querySelector(".dropdown-toggle");
      bootstrap.Dropdown.getInstance(toggle).hide();

      // Show a success message
      const toast = new bootstrap.Toast(
        document.querySelector(".toast.wine-added")
      );
      toast.show();
    });
  });
});

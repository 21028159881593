window.addEventListener("load", () => {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })

  // Enable Bootstrap tooltips
  document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  ).forEach(function (tooltipTriggerEl) {
    new bootstrap.Tooltip(tooltipTriggerEl)
  });

  // Format the phone number on the fly.
  const pnField = document.querySelector('#customer_phone_number')
  if (pnField !== undefined) {
    pnField.addEventListener('keyup', applyPhoneFormatting)
    applyPhoneFormatting()
  }
});

// (###) ###-####
function applyPhoneFormatting() {
    // Find the phone number field.
    const pnField = document.querySelector('#customer_phone_number')

    // Create the desired formatting using its value.
    input = pnField.value.replace(/\D/g,'')
    var size = input.length
    if (size>0) {input="("+input}
    if (size>3) {input=input.slice(0,4)+") "+input.slice(4,11)}
    if (size>6) {input=input.slice(0,9)+"-" +input.slice(9)}

    // Update the field's value.
    pnField.value = input
}
